import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from "recompose";
import { connect } from "react-redux";
import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form'
import { Content, LoadingState, ErrorState } from "components"
import { PurchaseDetails } from '../components'
import { CouponForm, BillingForm } from '../forms'
import * as apiActions from 'api-actions'
import * as effects from 'effects'
import { selectors as orderSelectors } from "../reducer";
import { selectors as activitySelectors } from '../../activities/reducer'
import { scrollToTop, trackPurchaseFromOrder, trackBeginCheckout, trackAddPaymentInfo } from 'utils'
import * as flashActions from 'redux-flash'
import { filter, isEmpty, first } from "lodash";
import AddOnDonationForm from "../forms/AddOnDonationForm";


const propTypes = {
  cart: Types.cart,
  fetchCart: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  activities: PropTypes.arrayOf(Types.activity),
  fetchActivities: PropTypes.func.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  deleteCoupon: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  verifyRecaptcha: PropTypes.func.isRequired
}

const defaultProps = {}

function Payment(
    {
      cart,
      fetchCart,
      updateCart,
      activities,
      fetchActivities,
      validateCoupon,
      deleteCoupon,
      flashErrorMessage,
      push,
      verifyRecaptcha
    }) {
  const [error, setError] = useState(null)
  const [cartTokenParam,] = useState(() => {
    return new URLSearchParams(window.location.search).get('cartToken')
  })

  useEffect(() => {
    const token = cartTokenParam || (cart ? cart.token : null)
    fetchCart(token)
        .catch(() => setError('An error occurred while fetching your cart.'))
    fetchActivities({viewAll: true})
        .catch(() => setError('An error occurred while loading your activities.'))
  }, [])

  useEffect(() => {
    if (cart && isEmpty(cart.lineItems)) push('/activities/cart')
  }, [cart])

  useEffect(() => {
    trackBeginCheckout(cart)
    trackAddPaymentInfo(cart)
  }, []);

  const skipPaymentDetails = cart.total <= 0

  if (error) return <ErrorState message={error}/>
  if (!cart || !activities) return <LoadingState/>

  return (
      <Content>
        <div className="ticket-details">
          <PurchaseDetails
              cart={cart}
              updateCart={updateCart}
              activities={activities}
              deleteCoupon={deleteCoupon}
          />
          <CouponForm
              onSubmit={(params) => {
                validateCoupon(cart, params)
                    .catch((result) => {
                      scrollToTop()
                      const message = result.errors ? result.errors.message : 'This coupon code could not be applied based on your selections.'
                      flashErrorMessage(message)
                    })
              }}
          />
          <AddOnDonationForm
              onSubmit={({donationAmount}) => {
                updateCart(cart, {donationAmount})
              }}
          />
        </div>
        <BillingForm
            cart={cart}
            skipPaymentDetails={skipPaymentDetails}
            updateCart={updateCart}
            onSubmit={(params) => {
              const timedActivities = filter(cart.lineItems, {timed: true})

              return verifyRecaptcha(window.grecaptcha.getResponse())
                  .then(gcaptchaResponse => {
                    // Current Flow
                    if (!isEmpty(timedActivities)) {
                      return checkCapacities(timedActivities)
                          .then(checkedCapacities => {
                            const soldOutLineItems = checkedCapacities.filter((capacity) => {
                              const lineItem = cart.lineItems.find(li => li.eventId === capacity.tourEventId)
                              const isSoldOut = !capacity || capacity.vacancy < lineItem.lineItemTickets.length
                              return isSoldOut
                            })
                            return soldOutLineItems
                          })
                          .then((soldOutLineItems) => {
                            if (!isEmpty(soldOutLineItems)) {
                              const error = getSoldOutErrorMessage(soldOutLineItems)
                              throw new SubmissionError(error)
                            } else {
                              return effects.createTicketingOrder(cart, params, skipPaymentDetails)
                            }
                          })
                    } else {
                      return effects.createTicketingOrder(cart, params, skipPaymentDetails)
                    }
                    // End of current flow
                  })
            }}
            onSubmitSuccess={(success) => {
              const order = success.data.attributes
              const { cart } = order

              trackPurchaseFromOrder(order, cart)
              push(`/orders/confirmation/${order.token}`)
            }}
            onSubmitFail={(error) => {
              scrollToTop()
              flashErrorMessage(error.message || 'Your order could not be submitted. Please check your information and try again.')
            }}
            recaptchaSiteKey={process.env.GOOGLE_RECAPTCHA_CLIENT_KEY}
        />
      </Content>
  )
}

const checkCapacities = async (lineItems) => {
  const checks = lineItems.map(lineItem => effects.checkCapacity(lineItem.eventId))
  return Promise.all(checks)
}

const getSoldOutErrorMessage = (soldOutLineItems) => {
  const soldOutError = {
    title: 'Sold out item(s)'
  }
  if (soldOutLineItems.length === 1) {
    soldOutError.message = `There are not enough tickets remaining for ${first(soldOutLineItems).eventName} to fulfill your order.`
  } else {
    const soldOutItemDisplayNames = soldOutLineItems.map(lineItem => lineItem.eventName)
    const lastActivity = soldOutItemDisplayNames.pop()
    const conjunction = soldOutLineItems.length === 2 ? ' and ' : ', and '
    const displayText = soldOutItemDisplayNames.join(', ') + conjunction + lastActivity
    soldOutError.message = `The following items have sold out: ${displayText}.`
  }
  return soldOutError
}

function mapStateToProps(state) {
  return {
    activities: activitySelectors.activities(state),
    cart: orderSelectors.cart(state)
  }
}

const mapDispatchToProps = {
  fetchCart: apiActions.fetchCart,
  updateCart: apiActions.updateCart,
  fetchActivities: apiActions.fetchActivities,
  validateCoupon: apiActions.validateCoupon,
  deleteCoupon: apiActions.deleteCoupon,
  verifyRecaptcha: apiActions.verifyRecaptcha,
  flashErrorMessage: flashActions.flashErrorMessage,
  push,
}

Payment.defaultProps = defaultProps
Payment.propTypes = propTypes

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    Payment
)
