import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import NavItem from './nav-item'
import PrimaryNavDropdown from './primary-nav-dropdown'
import { Link } from 'react-router'
import { CartIcon } from 'components'

const propTypes = {
  items: PropTypes.array.isRequired,
  currentFlow: PropTypes.string.isRequired,
  cart: Types.cart
}

const defaultProps = {
  cart: null
}

function Nav ({ items, cart, currentFlow }) {
  const cartActive = currentFlow === Types.ACTIVITY_PATH_PREFIX
  const getCartLinkLabel = () => {
    if (!cart || cart.lineItems.length === 0) return 'View cart (0 items)'
    return `View cart (${cart.lineItems.length} ${cart.lineItems.length > 1 ? 'items' : 'item'})`
  }
  return (
    <nav className="nav">
      <div className="nav-content">
        <div className="primary-nav">
          <a className="home-link" href="https://utaholympiclegacy.org/" target="_blank" rel="noopener noreferrer">
            <img className="logomark" src="https://s3.us-east-2.amazonaws.com/uolf-online-checkout-production/venue-logomarkers/logomark-white.png" alt="Utah Olympic Legacy Foundation"/>
          </a>
          <ul className="nav-items">
            { items.map((item, i) => {
                return item.children
                  ? <PrimaryNavDropdown key={ i } item={ item } />
                  : <NavItem key={ i } item={ item } classList="nav-item nav-item-no-dropdown" />
              })
            }
          </ul>
          {cartActive && (
            <Link 
              to="/activities/cart" 
              className="tickets cart-link" 
              aria-label={getCartLinkLabel()}
              >
              <CartIcon/>
              {cart && cart.lineItems.length > 0 && (
                <div className="cart-qty"><span>{cart.lineItems.length}</span></div>
              )}
            </Link>
          )}
          <Link to="/activities" className={'tickets' + (cartActive ? ' cart-active' : '')}>Purchase Tickets</Link>
        </div>
      </div>
    </nav>
  )
}

Nav.propTypes = propTypes
Nav.defaultProps = defaultProps

export default Nav
