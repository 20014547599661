import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from "recompose";
import { connect } from "react-redux";
import * as Types from 'types'
import * as apiActions from 'api-actions'
import { selectors as orderSelectors } from '../reducer'
import { LoadingState, ErrorState, Content, ExternalLink } from 'components'
import { isEmpty, groupBy, sum } from 'lodash'
import { displayCurrency, trackPurchase } from 'utils'

const propTypes = {
    order: Types.ticketingOrder,
    fetchOrder: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired
}

const defaultProps = {}

function OrderConfirmation ({
    params: {
        orderToken
    },
    order,
    fetchOrder,
}) {
    const [error, setError] = useState(null)
    useEffect(() => {
        fetchOrder(orderToken).catch(() => {
            if (!error) setError('An error occurred while loading your order.')
        })
    }, [])

    useEffect(() => {
      trackPurchase(order)
    }, []);

    if (error) return <ErrorState message={error}/>
    if (!order) return <LoadingState/>
    const displayTicketTypeText = groupedLineItemTickets => {
        const displayNames = Object.keys(groupedLineItemTickets).map(key => {
            return groupedLineItemTickets[key].length + ' ' + key
        })
        return displayNames.join(', ')
    }
    const getCouponSavings = () => {
        const appliedCouponItems = order.cart.appliedCouponItems
        if (isEmpty(appliedCouponItems)) return null
        const savings = appliedCouponItems.map(couponItem => parseFloat(couponItem.discounts))
        return sum(savings)
    }
    const couponSavings = getCouponSavings()
    return (
          <Content>
            <div className="confirmation">
              <h1 className="thank-you">Thank You</h1>
              <p>Your order has been received.</p>
              <div className="confirmation-card">
                <h4>Order Summary</h4>
                <ul>
                  <li>
                    <p className="order-summary-label">Order Number</p>
                    <p className="order-summary-value">{ order.receipt }</p>
                  </li>
                  <li>
                    <p className="order-summary-label">Purchase Total</p>
                    <p className="order-summary-value">{displayCurrency(order.totalPaid)}</p>
                  </li>
                  {couponSavings && (
                      <li>
                          <p className="order-summary-label">Coupon Savings</p>
                          <p className="order-summary-value">{displayCurrency(couponSavings)}</p>
                      </li>
                  )}
                  { order.paymentMethod &&
                    <li>
                      <p className="order-summary-label">Payment Method</p>
                      <p className="order-summary-value">{ order.paymentMethod }</p>
                    </li>
                  }
                  <hr aria-hidden="true"/>
                  {order.cart.lineItems.map(lineItem => {
                      const groupedLineItemTickets = groupBy(lineItem.lineItemTickets, 'displayName')
                      return (
                          <React.Fragment key={lineItem.id}>
                              <li>
                                  <p className="order-summary-label">Product</p>
                                  <p className="order-summary-value">{lineItem.displayName}</p>
                              </li>
                              {lineItem.timed && (
                                <li>
                                  <p className="order-summary-label">{lineItem.startDate === lineItem.endDate ? 'Date' : 'Start Date'}</p>
                                  <p className="order-summary-value">{lineItem.startDate}</p>
                                </li>
                              )}
                              <li>
                                <p className="order-summary-label">{Object.keys(groupedLineItemTickets).length > 1 ? 'Ticket Types' : 'Ticket Type'}</p>
                                <p className="order-summary-value">{displayTicketTypeText(groupedLineItemTickets)}</p>
                              </li>
                              <hr aria-hidden="true"/>
                          </React.Fragment>
                      )
                  })}
                  {order.cart.donationAmount && parseFloat(order.cart.donationAmount) > 0 && (
                      <React.Fragment>
                        <li>
                          <p className="order-summary-label">Product</p>
                          <p className="order-summary-value">Donation to U.O.L.F.</p>
                        </li>
                        <li>
                          <p className="order-summary-label">Amount</p>
                          <p className="order-summary-value">{displayCurrency(order.cart.donationAmount)}</p>
                        </li>
                        <li>
                            <p className="order-summary-value">Thank you!</p>
                        </li>
                      </React.Fragment>
                  )}
                </ul>
                <p>Ticket downloads and a copy of your receipt have been sent to { order.email }</p>

                {order.displayWaivers && !isEmpty(order.waivers) && (
                    <div className="waivers-container">
                      <p><span className="strong">Skip the line</span> and sign the required waiver(s) before you enter the park:</p>
                      {order.waivers.map((waiver) => {
                        return (
                            <ExternalLink to={waiver.waiverLink} key={waiver.waiverLink} aria-label={`Sign ${waiver.waiverName}`}>
                                {`Sign ${waiver.waiverName}`}
                            </ExternalLink>
                        )
                      })}
                    </div>
                )}
              </div>
            </div>
          </Content>
    )
}

function mapStateToProps(state) {
    return {
        order: orderSelectors.ticketingOrder(state),
    }
  }
  
  const mapDispatchToProps = {
    fetchOrder: apiActions.fetchTicketingOrder,
  }
  
  OrderConfirmation.defaultProps = defaultProps
  OrderConfirmation.propTypes = propTypes
  
  export default compose(connect(mapStateToProps, mapDispatchToProps))(
    OrderConfirmation
  )
  
