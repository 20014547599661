import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import classnames from "classnames";
import { formatDateFullDayAndDate } from "utils/date-formatting-utils";

const propTypes = {
  activityLineItem: PropTypes.object,
  change: PropTypes.func.isRequired,
  orderedActivityTimes: PropTypes.object,
  selectedDate: PropTypes.instanceOf(Date),
  setSelectedActivityTime: PropTypes.func.isRequired,
};

const defaultProps = {};

function TimeSlotSelector({
  activityLineItem,
  change,
  orderedActivityTimes,
  selectedDate,
  setSelectedActivityTime,
}) {
  const [selectedTime, setSelectedTime] = useState({});
  
  const apiDateKey = convertToFormattedKey(selectedDate);
  const dateTimes = orderedActivityTimes[apiDateKey];
  const formattedDate = formatDateFullDayAndDate(selectedDate);

  // If Activity Line exists, find and select the correct time in Time Selector
  useEffect(() => {
    if (activityLineItem) {
      const { eventId } = activityLineItem;
      const matchingActivity = Object.values(orderedActivityTimes)
      .flat()
      .find((activity) => activity.centamanId === eventId);
      if (matchingActivity) {
        setSelectedTime(matchingActivity);
      }
    }
  }, [activityLineItem, orderedActivityTimes, selectedTime]);

  return (
    <div className="time-selector">
      <div className="date-header">
        <span>ENTRY TIMES</span>
        {formattedDate}
      </div>
      <div className="separator"></div>
      <ul>
        {dateTimes &&
          dateTimes.map((item) => {
            const isDisabled = item.soldOut === true;
            return (
              <li
                key={item.centamanId}
                className={classnames({
                  active: item.centamanId === selectedTime.centamanId,
                  disabled: isDisabled,
                })}
              >
                <button
                  type="button"
                  disabled={isDisabled}
                  onClick={() => {
                    setSelectedTime(item);
                    setSelectedActivityTime(item);
                    change("selectedTime", item);
                  }}
                >
                  {item.displayStartTime}-{item.displayEndTime}
                  <span>${item.tickets[0].price}</span>
                </button>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

TimeSlotSelector.defaultProps = defaultProps;
TimeSlotSelector.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    selectedDate:
      formValueSelector("calendar-booking-form")(state, "selectedDate") ||
      new Date(),
  };
}

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TimeSlotSelector
);

const convertToFormattedKey = (date) => {
  // Get Date
  const originalDate = new Date(date);
  // Get Full Year
  const year = originalDate.getFullYear();
  // Get zero-based Month
  const month = String(originalDate.getMonth() + 1).padStart(2, "0");
  // Get zero-based Day
  const day = String(originalDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
