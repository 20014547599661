import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { selectors } from "../reducer"
import { compose } from "recompose"
import { connect } from "react-redux"
import { find } from 'lodash'
import * as apiActions from 'api-actions'
import { TabBar, Spinner } from "lp-components"
import * as Types from "types"
import { Content, ErrorState, LoadingState } from "components"
import ParkMap from "../components/ParkMap"
import VenueActivitityList from "../components/VenueActivityList"
import { trackViewItemList } from 'utils'

const propTypes = {
  fetchVenues: PropTypes.func.isRequired,
  venues: PropTypes.arrayOf(Types.venue),
}

const defaultProps = {}

function Activities({ fetchVenues, venues }) {
  const [error, setError] = useState(null)

  // fetch venue (including activity data) when component mounts
  useEffect(() => {
    fetchVenues()
    .catch(() => {
      setError('Activities could not be loaded.')
    })
  }, [])

  useEffect(() => {
    trackViewItemList()
  }, []);

  const [venueParam, setVenueParam] = useState(() => {
    return new URLSearchParams(window.location.search).get('venue') || Types.ALL_ACTIVITIES_VENUE_SLUG
  })

  const venueData = useMemo(() => {
    if (!venues) return null
    return find(venues, { slug: venueParam }) || find(venues, { slug: Types.ALL_ACTIVITIES_VENUE_SLUG })
  }, [venueParam, venues])
  

  if (error) return <ErrorState error={error}/>
  if (!venues) return <LoadingState/>

  const venueNames = venues.map((v) => v.name)

  const findAndSelectVenueByName = (name) => {
    const venue = find(venues, { name })
    setVenueParam(venue.slug)
  }

  const getLocationLabel = (activityId) => {
    if (!venueData.slug === Types.ALL_ACTIVITIES_VENUE_SLUG) return venueData.displayName
    const specificVenue = venues.find(venue => {
      const isAllActivitiesVenue = venue.slug !== Types.ALL_ACTIVITIES_VENUE_SLUG
      return venue.activities.find(activity => isAllActivitiesVenue && activity.id === activityId)
    })
    return specificVenue.name || venueData.name
  }

  const getLocationMarker = (activity, venue) => {
    if (venue.slug !== Types.ALL_ACTIVITIES_VENUE_SLUG) return venue.locationMarker
    const correspondingVenue = find(venues, { id: activity.venueId })
    return correspondingVenue.locationMarker
  }

  return (
    <Content>
      <div className="all-tickets">
        <TabBar
          options={venueNames}
          value={venueData ? venueData.name : null}
          onChange={(name) => findAndSelectVenueByName(name)}
        />
        <VenueActivitityList venue={venueData} getLocationLabel={getLocationLabel} getLocationMarker={getLocationMarker}/>
      </div>
      {venueData ? <ParkMap venue={venueData} /> : <Spinner />}
    </Content>
  )
}

Activities.propTypes = propTypes
Activities.defaultProps = defaultProps


function mapStateToProps(state) {
  return {
    venues: selectors.venues(state),
  }
}

const mapDispatchToProps = {
  fetchVenues: apiActions.fetchVenues,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Activities
);
