import React from "react";
import PropTypes from "prop-types";
import * as Types from "types";
import exact from "prop-types-exact";

const propTypes = {
  ticket: Types.lineItemTicket.isRequired,
  quantity: PropTypes.number,
  addTicket: PropTypes.func.isRequired,
  removeTicket: PropTypes.func.isRequired,
  maxQuantity: PropTypes.number.isRequired,
  totalLineItemQuantity: PropTypes.number,
  showError: PropTypes.func.isRequired,
};

const defaultProps = {
  quantity: 0,
  totalLineItemQuantity: 0,
};

function TicketQuantity({
  ticket,
  quantity,
  addTicket,
  removeTicket,
  maxQuantity,
  totalLineItemQuantity,
  showError,
}) {
  return (
    <div className="ticket-amount add-on">
      <span className="button-group">
        <button
          aria-label={`Remove one ${ticket.displayName}`}
          onClick={() => {
            if (quantity === 0) return;
            removeTicket(ticket);
          }}
          type="button"
          className="button-ticket-amount"
        >
          -
        </button>
        <p className="quantity">{quantity}</p>
        <button
          aria-label={`Add one ${ticket.displayName}`}
          onClick={() => {
            if (maxQuantity && totalLineItemQuantity + 1 <= maxQuantity) {
              addTicket(ticket);
            } else {
              showError(
                `Only ${maxQuantity} tickets may be purchased at a time.`
              );
            }
          }}
          type="button"
          className="button-ticket-amount"
        >
          +
        </button>
      </span>

      <div className="divider"></div>

      <div>
        <p className="ticket-name">{ticket.displayName}</p>
        <p className="ticket-description">{ticket.description}</p>
        <p className="ticket-price">
          {quantity} ticket{quantity !== 1 ? "s" : ""} x ${(ticket.price)}
        </p>
      </div>

      <div className="subtotal-block">
        <p className="subtotal-header">Subtotal</p>
        <p className="subtotal-amount">${quantity * ticket.price}</p>
      </div>
    </div>
  );
}

TicketQuantity.propTypes = exact(propTypes);
TicketQuantity.defaultProps = defaultProps;

export default React.memo(TicketQuantity);
