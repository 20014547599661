import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as Types from "types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Content, LoadingState, CartIcon } from "components";
import classnames from "classnames";
import { isEmpty, find } from "lodash";
import { Link } from "react-router";
import { selectors as cartSelectors } from "../reducer";
import { selectors as activitySelectors } from "../../activities/reducer";
import * as apiActions from "api-actions";
import { CartLineItem } from "../components";
import { displayCurrency, trackViewCart } from "utils";

const propTypes = {
    cart: Types.cart,
    fetchCart: PropTypes.func.isRequired,
    activities: PropTypes.arrayOf(Types.activity),
    fetchActivities: PropTypes.func.isRequired,
    deleteLineItem: PropTypes.func.isRequired
};

const defaultProps = {};

function Cart({
    cart,
    fetchCart,
    activities,
    fetchActivities,
    deleteLineItem,
}) {
    // fetch cart and activities when page loads
    useEffect(() => {
        fetchActivities({ viewAll: true });
        fetchCart(cart ? cart.token : null);
    }, []);

    useEffect(() => {
        trackViewCart(cart);
    }, []);

    if (!cart || !activities) return <LoadingState />;

    const hasTaxes = cart.taxes > 0;

    return (
        <Content>
            <h1 className="h4">Shopping Cart</h1>
            {isEmpty(cart.lineItems) ? (
                <div className="shopping-cart shopping-cart-empty">
                    <CartIcon fillColor="#A9A9A9" />
                    <p>Your Cart is Empty!</p>
                    <Link to="/activities" className="button-primary">
                        Browse Activities
                    </Link>
                </div>
            ) : (
                <div className="shopping-cart">
                    <div className="shopping-cart-header">
                        <h2 className="h4">Product Details</h2>
                    </div>
                    {cart.lineItems.map((lineItem) => {
                        const activity = find(activities, { id: lineItem.activityId });
                        return (
                            <CartLineItem
                                key={lineItem.id}
                                lineItem={lineItem}
                                activity={activity}
                                handleDeleteLineItem={() => {
                                    deleteLineItem(cart, lineItem);
                                }}
                            />
                        );
                    })}
                    <div className="order-line-item">
                        <div className={classnames({ subtotal: hasTaxes })}>
                            {hasTaxes ? <p>Subtotal</p> : <h5>Total</h5>}
                            <div className="ticket-info">
                                <p className={classnames({ total: !hasTaxes })}>
                                    {displayCurrency(cart.subtotal)}
                                </p>
                            </div>
                        </div>
                    </div>
                    {hasTaxes && (
                        <React.Fragment>
                            <div className="order-line-item">
                                <div className={classnames({ taxes: hasTaxes })}>
                                    <p>Taxes</p>
                                    <div className="ticket-info">
                                        <p>{displayCurrency(cart.taxes)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="order-line-item subtotal">
                                <div className="product-item">
                                    <h2 className="total">Total</h2>
                                </div>
                                <div className="ticket-info">
                                    <p className="total">{displayCurrency(cart.total)}</p>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <div className="button-three-options">
                        <Link
                            className="button-primary"
                            to={`/activities/payment?cartToken=${cart.token}`}
                        >
                            Continue to Payment
                        </Link>
                        <Link className="button-chill" to="/activities">
                            Add Another Activity
                        </Link>
                    </div>
                </div>
            )}
        </Content>
    );
}

function mapStateToProps(state) {
    return {
        cart: cartSelectors.cart(state),
        activities: activitySelectors.activities(state)
    };
}

const mapDispatchToProps = {
    fetchCart: apiActions.fetchCart,
    fetchActivities: apiActions.fetchActivities,
    deleteLineItem: apiActions.deleteLineItem
};

Cart.propTypes = propTypes;
Cart.defaultProps = defaultProps;

export default compose(connect(mapStateToProps, mapDispatchToProps))(Cart);
