import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import * as Types from "types"
import { compose } from "recompose"
import { lpForm } from "lp-form"
import { Field } from "redux-form"
import { isEmpty } from 'lodash'
import { Select } from "lp-components"
import moment from "moment"
import { MomentDateInput } from "components"
import { CalendarRadioGroup } from "../components"
import { getExcludedDatesFromTimeSlots } from "utils"
import { addDays } from "date-fns"


const propTypes = {
  activity: Types.activity.isRequired,
  activityTimes: PropTypes.arrayOf(Types.activityTime).isRequired,
  selectedDate: PropTypes.object,
  setSelectedActivityTime: PropTypes.func.isRequired,
  selectedActivityTime: Types.activityTime,
  setSelectedDate: PropTypes.func.isRequired,
  fetchActivityTimes: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired
}

const defaultProps = {
  selectedActivityTime: null,
  selectedDate: null,
  activityLineItem: null
}

function ActivityDateTimeForm({
  activity,
  activityTimes,
  selectedDate,
  setSelectedDate,
  setSelectedActivityTime,
  selectedActivityTime,
  fetchActivityTimes,
  handleSubmit,
  change
}) {
  const today = new Date()

  const getTimesForDate = (val) => {
    if (!val) return []
    const date = val.format('YYYY-MM-DD')
    return activityTimes.filter(activityTime => {
      return activityTime.startDate === date
    })
  }
  const [timesForDate, setTimesForDate] = useState(getTimesForDate(selectedDate))

  useEffect(() => {
    if (selectedDate && activityTimes.length > 0) {
      setTimesForDate(getTimesForDate(selectedDate));
    }
  }, [selectedDate, activityTimes]);

  const handleMonthChange = (time) => {
    const startDate = `${time.year()}-${time.month()}-01`
    fetchActivityTimes({ activityId: activity.id, startDate })
  }

  const handleDateSelect = (val) => {
    setSelectedActivityTime(null)
    setSelectedDate(val)
    const availableTimes = getTimesForDate(val)
    setTimesForDate(availableTimes)
    change('activityTimeId', '')
  }

  const useCalendarSelect = activity.dateSelectionType === Types.CALENDAR_DATE_SELECT_TYPE
  const soldOutMesage = (useCalendarSelect && activity.displaySoldOutMessage && !isEmpty(timesForDate)) ? activity.soldOutMessage : ""
  const radioTimeOptions = timesForDate.map(at => ({
    key: at.displayStartTime,
    value: at.centamanId,
    soldOut: at.soldOut
  }));

  return (
    <form onSubmit={handleSubmit}>
      <div aria-label="Step 1">
        <h3 className="h4">
          <span className="step">1</span>
          Select Date and Time
        </h3>
        {useCalendarSelect && (
          <Field
            name="date"
            component={MomentDateInput}
            excludeDates={getExcludedDatesFromTimeSlots(
              activityTimes,
              today,
              addDays(today, Types.DEFAULT_ADDITIONAL_ACTIVITY_TIME_DAYS),
              true, // include soldout dates to show unavailable
            )}
            selected={selectedDate}
            minDate={today}
            onMonthChange={handleMonthChange}
            onChange={(e, val) => handleDateSelect(val)}
            label={false}
            aria-label="Select Date"
          />
        )}
        {!useCalendarSelect && (
          <Field
            name="activityTimeId"
            label={false}
            component={Select}
            placeholder="Please Select a Session"
            enablePlaceholderOption={true}
            options={formatActivityTimes(activityTimes)}
            aria-label="Select Date and Time"
          />
        )}
        {soldOutMesage && <p className="explanation-copy">{soldOutMesage}</p>}
        {useCalendarSelect && selectedDate && (
          <div aria-label="Select a time" className="select-time">
            {isEmpty(timesForDate) ? (
              <p className="explanation-copy">Please select a valid date to view available times.</p>
            ) : (
              <Field
                name="activityTimeId"
                component={CalendarRadioGroup}
                options={radioTimeOptions}
                selectedActivityTime={selectedActivityTime}
              />
            )}
          </div>
        )}
      </div>
    </form>
  )
}

const formatActivityTimes = (activityTimes) => {
  return activityTimes.map((activityTime) => {
    const formattedDateTime = `${moment(activityTime.startDate).format(
      "dddd, MMMM D - "
    )}${activityTime.displayStartTime}-${activityTime.displayEndTime
      } (${getSpotsRemainingText(activityTime.vacancy)})`
    return { key: formattedDateTime, value: activityTime.centamanId }
  })
}

const getSpotsRemainingText = (spotsRemaining) => {
  if (spotsRemaining <= 0) return "SOLD OUT"
  if (spotsRemaining === 1) return "1 spot left"

  return spotsRemaining + " spots left"
}


ActivityDateTimeForm.propTypes = propTypes;
ActivityDateTimeForm.defaultProps = defaultProps;

export default compose(
  lpForm({
    name: "activity-date-time-form",
    constraints: {},
    submitOnChange: true,
  })
)(ActivityDateTimeForm)
