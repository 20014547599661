import React from "react";
import PropTypes from "prop-types";
import * as Types from "types";
import { find, isEmpty } from "lodash";
import CartLineItem from "./CartLineItem";
import { displayCurrency } from "utils";

const propTypes = {
    cart: Types.cart.isRequired,
    updateCart: PropTypes.func.isRequired,
    activities: PropTypes.arrayOf(Types.activity),
    deleteCoupon: PropTypes.func.isRequired
};

const defaultProps = {};

function PurchaseDetails({ cart, updateCart, activities, deleteCoupon }) {
    const containsDonation = parseFloat(cart.donationAmount) > 0;
    const containsCoupons = !isEmpty(cart.appliedCouponItems);
    const hasTaxes = cart.taxes > 0;

    return (
        <div>
            <div className="purchase-details">
                <h1 className="h4">Purchase Details</h1>
            </div>
            <div className="billing-details">
                {(cart.lineItems || []).map((lineItem) => {
                    const activity = find(activities, { id: lineItem.activityId });
                    return (
                        <CartLineItem
                            key={lineItem.id}
                            lineItem={lineItem}
                            activity={activity}
                        />
                    );
                })}
                {containsDonation && (
                    <div className="order-line-item">
                        <div className="product-item">
                            <h5>Donation to UOLF</h5>
                            <p className="total">{displayCurrency(cart.donationAmount)}</p>
                            <div>
                                <button
                                    type="button"
                                    aria-label={`Remove ${displayCurrency(
                                        cart.donationAmount
                                    )} donation from cart`}
                                    onClick={() => {
                                        updateCart(cart, { donationAmount: 0 });
                                    }}
                                    className="remove"
                                >
                                    X
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {containsDonation && <hr aria-hidden="true" />}
                {containsCoupons &&
                    cart.appliedCouponItems.map((coupon) => {
                        return (
                            <div className="order-line-item" key={coupon.coupon}>
                                <div className="product-item">
                                    <h5 className="discount-description">
                                        {"Discount code: " + coupon.coupon}
                                    </h5>
                                    <p className="total discount-description">
                                        {displayCurrency(coupon.discounts)}
                                    </p>
                                    <button
                                        type="button"
                                        className="discount-description remove"
                                        aria-label={`Remove coupon code ${coupon.coupon} from cart`}
                                        onClick={() => {
                                            deleteCoupon(cart, coupon.coupon);
                                        }}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                {containsCoupons && <hr aria-hidden="true" />}
                <div className="order-line-item">
                    <div className="line-item-group">
                        <p>Subtotal:</p>
                        <p>{displayCurrency(cart.subtotal)}</p>
                    </div>
                    {hasTaxes > 0 && (
                        <div className="line-item-group">
                            <p>Taxes:</p>
                            <p>{displayCurrency(cart.taxes)}</p>
                        </div>
                    )}
                    <div className="order-line-total subtotal">
                        <h3 className="h5">Total:</h3>
                        <p className="total">{displayCurrency(cart.total)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

PurchaseDetails.propTypes = propTypes;
PurchaseDetails.defaultProps = defaultProps;

export default PurchaseDetails;
