import React from 'react'
import exact from 'prop-types-exact'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router'

const propTypes = {
    handleAddNewSession: PropTypes.func,
}

const defaultProps = {
    handleAddNewSession: null
}

function ActivityActions ({ handleAddNewSession }) {
    return (
        <div className="button-three-options">
            {handleAddNewSession && (
                <button type="button" onClick={() => handleAddNewSession()} className="button-chill add-new-session">Add Another Session</button>
            )}
            <Link to="/activities" className="button-chill">
                Back to Activities
            </Link>
            <Link to="/activities/cart" className="button-chill">
                Proceed to Checkout
            </Link>
        </div>
    )
}

ActivityActions.propTypes = exact(propTypes)
ActivityActions.defaultProps = defaultProps

export default React.memo(ActivityActions)