import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Types from "types";
import exact from "prop-types-exact";
import { BookingUdfForm } from "../forms";
import { convertUdfsArrayToObj, serializeUdfs } from "utils";
import { Modal } from "lp-components";
import { find } from "lodash";

const propTypes = {
  cart: Types.cart.isRequired,
  lineItem: Types.lineItem.isRequired,
  activity: Types.activity.isRequired,
  updateLineItem: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};

const defaultProps = {};

function ReviewBookingDetails({
  cart,
  lineItem,
  activity,
  updateLineItem,
  showError,
}) {
  const [editing, setEditing] = useState(false);
  const displayableBookingUdfs = activity.bookingUdfs.map((udf) => {
    const lineItemUdf = find(lineItem.bookingUdfs, {
      fieldName: udf.fieldName,
    });
    return {
      label: udf.label,
      value: lineItemUdf ? lineItemUdf.value : null,
      id: udf.id,
    };
  });

  return (
    <div>
      <h4>Booking Details</h4>
      {editing ? (
        <Modal
          onClose={() => setEditing(false)}
          hideCloseButton={true}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
        >
          <BookingUdfForm
            activity={activity}
            onSubmit={(params) => {
              const updatedLineItem = {
                ...lineItem,
                bookingUdfs: serializeUdfs(params, lineItem.bookingUdfs),
              };
              updateLineItem(cart, updatedLineItem)
                .then(() => {
                  setEditing(false);
                })
                .catch((err) => showError(err));
            }}
            onCancel={() => setEditing(false)}
            initialValues={convertUdfsArrayToObj(lineItem.bookingUdfs)}
          />
        </Modal>
      ) : (
        <div className="details-wrapper">
          <div className="booking-details">
            {displayableBookingUdfs.map((udf) => {
              return (
                <div key={udf.id} className="details">
                  <span
                    className="detail-header"
                    aria-label={udf.label}
                  >{udf.label}</span>
                </div>
              );
            })}
            <button
              className="button-primary button-small"
              aria-label="Edit booking details"
              onClick={() => setEditing(true)}
            >
              Edit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

ReviewBookingDetails.propTypes = exact(propTypes);
ReviewBookingDetails.defaultProps = defaultProps;

export default React.memo(ReviewBookingDetails);
