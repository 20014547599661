import React, { useState } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import * as Types from "types";
import ReviewAttendeeDetails from "./ReviewAttendeeDetails";
import { getTimeDisplayText, sumCurrencyValues, displayCurrency } from "utils";
import { groupBy, first } from "lodash";

const propTypes = {
  cart: Types.cart.isRequired,
  activity: Types.activity.isRequired,
  lineItem: Types.lineItem.isRequired,
  updateLineItemTicket: PropTypes.func.isRequired,
  deleteLineItemTicket: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};

const defaultProps = {};

function ReviewLineItem({
  cart,
  activity,
  lineItem,
  updateLineItemTicket,
  deleteLineItemTicket,
  showError,
}) {
  const [editAttendee, setEditAttendee] = useState(null);
  const groupedLineItemTickets = groupBy(
    lineItem.lineItemTickets,
    "displayName"
  );
  const lineItemTotal = sumCurrencyValues(lineItem.lineItemTickets, "total");

  return (
    <div>
      {activity.collectAttendeeDetails && (
        <div>
          <h4>
            <span className="step">3</span>
            Attendee Details
          </h4>
        </div>
      )}
      {activity.collectAttendeeDetails &&
        lineItem.lineItemTickets.map((ticket, i) => {
          return (
            ticket.attendee && (
              <ReviewAttendeeDetails
                key={ticket.id}
                heading={`Attendee #${i + 1}`}
                cart={cart}
                lineItem={lineItem}
                lineItemTicket={ticket}
                activity={activity}
                updateLineItemTicket={updateLineItemTicket}
                deleteLineItemTicket={deleteLineItemTicket}
                editing={editAttendee === ticket.id}
                openAttendeeForm={(ticketId) => setEditAttendee(ticketId)}
                closeAttendeeForm={() => setEditAttendee(null)}
                showError={showError}
              />
            )
          );
        })}
      <div>
        <div>
          <h3 className="h4">
            <span className="step">{activity.timed ? "4" : "2"}</span>
            Review Selections
          </h3>
        </div>
        <div className="order-summary receipt-edge">
          {Object.keys(groupedLineItemTickets).map((ticketGroupKey) => {
            const tickets = groupedLineItemTickets[ticketGroupKey];
            const subtotal = sumCurrencyValues(tickets, "subtotal");
            const taxes = sumCurrencyValues(tickets, "taxes");
            const formattedSubtotal = displayCurrency(subtotal);
            const formattedTaxes = displayCurrency(taxes);
            return (
              <div key={ticketGroupKey} className="order-line-item">
                <div className="line-item-group">
                  <h5>{ticketGroupKey}</h5>
                  <p
                    className="total"
                    aria-label={`Total price for ${ticketGroupKey}: ${formattedSubtotal}`}
                  >
                    {formattedSubtotal}
                  </p>
                </div>
                {activity.timed && (
                  <p>{getTimeDisplayText(lineItem, activity)}</p>
                )}
                <p>
                  {tickets.length} x {first(tickets).displayName}
                </p>
                {taxes > 0 && (
                  <div className="line-item-group">
                    <p>Taxes</p>
                    <p
                      className="taxes"
                      aria-label={`Taxes price for ${ticketGroupKey}: ${formattedTaxes}`}
                    >
                      {formattedTaxes}
                    </p>
                  </div>
                )}
                <hr aria-hidden="true" />
              </div>
            );
          })}
          <div className="order-line-total subtotal">
            <h5>Total</h5>
            <p className="total">{displayCurrency(lineItemTotal)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

ReviewLineItem.propTypes = exact(propTypes);
ReviewLineItem.defaultProps = defaultProps;

export default React.memo(ReviewLineItem);
