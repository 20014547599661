import {
  requestWithKey,
  createPostRequest,
  createGetRequest,
  createPatchRequest,
  createDeleteRequest,
  stubRequest,
} from "lp-redux-api";
import {
  compose,
  countBy,
  getOr,
  map,
  formatYearMonthDay,
  getExcludedDatesFromTimeSlots,
  mapFormValuesToArray,
  set,
} from "utils";
import * as Types from "types";
import * as LS from "local-storage";
import * as Fixtures from "fixtures";
import { format, addDays } from "date-fns";

export const REQ_ACTIVITIES = "REQ_ACTIVITIES";
export const REQ_ACTIVITY_TIME = "REQ_ACTIVITY_TIME";
export const REQ_ACTIVITY_LEVELS = "REQ_ACTIVITY_LEVELS";
export const FETCH_ACTIVITY = "REQ_ACTIVITY";
export const REQ_ORDER = "REQ_ORDER";
export const REQ_CHECKOUT = "REQ_CHECKOUT";
export const REQ_MEMBERSHIP_CHECKOUT = "REQ_MEMBERSHIP_CHECKOUT";
export const REQ_NAV_ITEMS = "REQ_NAV_ITEMS";
export const REQ_ADD_ONS = "REQ_ADD_ONS";

export const fetchCart = createGetRequest("FETCH_CART", (cartToken = null) => {
  const query = cartToken ? { cartToken } : {};
  return {
    url: "/ticketing/cart",
    query,
    successDataPath: "data.attributes",
  };
});

export const updateCart = createPatchRequest(
  "UPDATE_CART",
  (cart, newValues) => ({
    url: `/ticketing/cart?cart_token=${cart.token}`,
    body: {
      cart: newValues,
    },
    successDataPath: "data.attributes",
  })
);

export const createLineItem = createPostRequest(
  "CREATE_LINE_ITEM",
  (
    cart,
    { activityId, centamanId, startDatetime, endDatetime, name },
    { activityTimeId },
    bookingUdfs,
    primaryActivityId
  ) => ({
    url: `/ticketing/line_items?cart_token=${cart.token}`,
    body: {
      activityId,
      activityTimeId,
      startDatetime,
      endDatetime,
      eventName: name,
      eventId: centamanId,
      bookingUdfs,
      primaryActivityId,
    },
    successDataPath: "data.additionalData.cart",
  })
);

export const updateLineItem = createPatchRequest(
  "UPDATE_LINE_ITEM",
  (cart, lineItem) => ({
    url: `/ticketing/line_items/${lineItem.id}?cart_token=${cart.token}`,
    body: lineItem,
    successDataPath: "data.additionalData.cart",
  })
);

export const deleteLineItem = createDeleteRequest(
  "DELETE_LINE_ITEM",
  (cart, lineItem) => ({
    url: `/ticketing/line_items/${lineItem.id}?cart_token=${cart.token}`,
    successDataPath: "data.attributes",
  })
);

export const createLineItemTicket = createPostRequest(
  "CREATE_LINE_ITEM_TICKET",
  (cart, lineItem, { id, price, costRateId, attendee, attendeeUdfs }) => ({
    url: `/ticketing/line_items/${lineItem.id}/line_item_tickets?cart_token=${cart.token}`,
    body: {
      attendee,
      attendeeUdfs,
      costRateId,
      price,
      ticketId: id,
    },
    successDataPath: "data.attributes",
  })
);

export const updateLineItemTicket = createPatchRequest(
  "UPDATE_LINE_ITEM_TICKET",
  (cart, lineItem, lineItemTicket) => ({
    url: `/ticketing/line_items/${lineItem.id}/line_item_tickets/${lineItemTicket.id}?cart_token=${cart.token}`,
    body: lineItemTicket,
    successDataPath: "data.attributes",
  })
);

export const deleteLineItemTicket = createDeleteRequest(
  "DELETE_LINE_ITEM_TICKET",
  (cart, lineItem, lineItemTicket) => ({
    url: `/ticketing/line_items/${lineItem.id}/line_item_tickets/${lineItemTicket.id}?cart_token=${cart.token}`,
    successDataPath: "data.attributes",
  })
);

export const fetchActivityTimes = createGetRequest(
  "FETCH_ACTIVITY_TIMES",
  ({ activityId, includeTickets = false, startDate }) => {
    return {
      url: `/ticketing/activities/${activityId}/activity_times`,
      query: {
        startDate: startDate || formatYearMonthDay(),
        endDate: formatYearMonthDay(
          addDays(new Date(), Types.DEFAULT_ADDITIONAL_ACTIVITY_TIME_DAYS)
        ),
        includeTickets,
      },
      successDataPath: "data.attributes",
    };
  }
);

export const fetchTickets = createGetRequest(
  "FETCH_TICKETS",
  (activityId, activityTimeId) => {
    return {
      url: `/ticketing/activities/${activityId}/activity_times/${activityTimeId}`,
      query: {
        includeTickets: true,
      },
      successDataPath: "data.attributes.tickets",
    };
  }
);

export const fetchExcludedDates = createGetRequest(
  "FETCH_EXCLUDED_DATES",
  ({ activityId, startDate, endDate }) => {
    return {
      url: `/ticketing/activities/${activityId}/activity_times`,
      query: {
        startDate: format(startDate, "YYYY-MM-DD"),
        endDate: format(endDate, "YYYY-MM-DD"),
      },
      successDataPath: "data.attributes",
      onSuccess: (timeSlots) => {
        // Transform the response (time slots) into the response we want (excluded dates)
        const excludedDates = getExcludedDatesFromTimeSlots(
          timeSlots,
          startDate,
          endDate
        );
        return { activityId, excludedDates, startDate, endDate };
      },
    };
  }
);

export function fetchActivityLevels(activityId, activityTimeId) {
  const url = `/activity_levels`;
  return requestWithKey(REQ_ACTIVITY_LEVELS, {
    url,
    query: { activityId, activityTimeId },
  });
}

export const fetchActivities = createGetRequest(
  "FETCH_ACTIVITIES",
  ({ viewAll = false } = {}) => {
    return {
      url: `/ticketing/activities`,
      successDataPath: "data.attributes",
      query: { viewAll },
    };
  }
);

export const fetchActivity = createGetRequest(FETCH_ACTIVITY, (activityId) => {
  return {
    url: `/ticketing/activities/${activityId}`,
    successDataPath: "data.attributes",
  };
});

export function fetchActivityTime(activityId, activityTimeId) {
  const url = `/ticketing/activities/${activityId}/activity_times/${activityTimeId}`;
  return requestWithKey(REQ_ACTIVITY_TIME, { url });
}

export const fetchVenues = createGetRequest("FETCH_VENUES", () => {
  return {
    url: "/venues?include_activities=true",
    successDataPath: "data.attributes",
  };
});

export const fetchTicketingOrder = createGetRequest(
  "FETCH_TICKETING_ORDER",
  (orderToken) => ({
    url: `/ticketing/orders/${orderToken}`,
    successDataPath: "data.attributes",
  })
);

export const fetchMembershipOrder = createGetRequest(
  "MEMBERSHIP_ORDER_DETAILS",
  (orderId) => ({
    url: `/memberships/orders/${orderId}`,
    successDataPath: "data.attributes",
  })
);

export function fetchNavItems() {
  const url = `/nav`;
  return requestWithKey(REQ_NAV_ITEMS, { url });
}

export function fetchAddOns(activityId, activityTimeId) {
  const url = `ticketing/activities/${activityId}/add_ons`;
  return requestWithKey(REQ_ADD_ONS, { url, query: { activityTimeId } });
}

export const requestMemberLogin = createPostRequest(
  "MEMBER_LOGIN",
  (params) => ({
    url: "login",
    body: params,
    successDataPath: "data.attributes",
    onFailure: ({ errors }) => ({
      errors: {
        _error:
          errors.message ||
          "Unable to authenticate at this time. Please try again.",
      },
    }),
  })
);

export const requestMemberDetails = createPostRequest(
  "MEMBER_DETAILS",
  (params) => ({
    url: "memberships/forgot-password",
    body: params,
    successDataPath: "",
  })
);

export const registerMember = createPostRequest(
  "REGISTER_MEMBER",
  ({ newMember }) => ({
    url: "memberships/register",
    body: newMember,
    successDataPath: "data.attributes",
    onFailure: ({ errors }) => ({
      errors: {
        _error:
          errors.message ||
          "Unable to register at this time. Please try again.",
      },
    }),
  })
);

export const fetchMembershipDetails = createGetRequest(
  "MEMBERSHIP_DETAILS",
  (membershipId) => ({
    url: `/memberships/memberships/${membershipId}`,
    successDataPath: "data.attributes",
  })
);

export const updateMembershipCheckout = createPostRequest(
  REQ_MEMBERSHIP_CHECKOUT,
  (args = {}) => {
    const initialCheckout = {
      ...LS.getCheckout(),
      ...args,
    };
    const membershipSelectionCounts = countBy(
      [
        getOr({}, "primaryMember", initialCheckout),
        ...getOr([], "secondaryMembers", initialCheckout),
        ...mapFormValuesToArray(initialCheckout.addOnMembers),
      ],
      "membershipTypeId"
    );

    const updatedCheckout = compose(
      set(
        "membershipSelections",
        map(membershipSelectionCounts, (quantity, centamanId) => {
          return { quantity, centamanId };
        })
      )
    )(initialCheckout);

    // Keep secondary and add-on members separate in LS for UI organization
    LS.setItem("checkout", updatedCheckout);
    return {
      url: "/memberships/checkouts",
      body: { orderInfo: updatedCheckout },
    };
  }
);

export const resetPassword = createPostRequest("RESET_PASSWORD", (params) => ({
  url: "/memberships/reset-password",
  body: params,
}));

export const checkMembershipCoupon = createGetRequest(
  "CHECK_MEMBERSHIP_COUPON",
  (coupon, membershipId) => {
    const params = `product_area=Memberships&membership_id=${membershipId}`;
    return {
      url: `/memberships/coupons/${coupon}?${params}`,
    };
  }
);

export const validateCoupon = createGetRequest(
  "CHECK_TICKETING_COUPON",
  (cart, { couponCode }) => {
    return {
      url: `/ticketing/coupons/validate/${couponCode}?cart_token=${cart.token}`,
      successDataPath: "data.attributes",
    };
  }
);

export const deleteCoupon = createDeleteRequest(
  "DELETE_COUPON",
  (cart, couponCode) => ({
    url: `/ticketing/coupons/${couponCode}?cart_token=${cart.token}`,
    successDataPath: "data.attributes",
  })
);

export const verifyRecaptcha = createPostRequest(
  "VERIFY_RECAPTCHA",
  (captchaResponse) => {
    return {
      root: process.env.API_URL,
      url: "/verify",
      body: {
        captchaResponse,
      },
      onFailure: (e) => {
        // Service will return a 422 if validation of the token fails
        if (e && e.status === 422)
          return { errors: { recaptcha: "Verification failed." } };

        return e;
      },
    };
  }
);

export const fetchAvailableCalendarDates = stubRequest(
  "FETCH_AVAILABLE_CALENDAR_DATES",
  () => {
    return Fixtures.fetchAvailableCalendarDates;
  }
);

export const fetchDynamicTickets = stubRequest(
  "FETCH_DYNAMIC_TICKETS",
  (activityTimeId) => {
    const fetchedTicket = Fixtures.fetchDynamicTickets.find(
      (activity) => activity.centaman_id === activityTimeId
    );

    return fetchedTicket.tickets;
  }
);
