import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Types from "types";
import exact from "prop-types-exact";
import { Link } from "react-router";
import { Modal, Button } from "lp-components";
import { groupBy } from "lodash";
import { displayCurrency, sumCurrencyValues, getTimeDisplayText } from "utils";

const propTypes = {
    lineItem: Types.lineItem.isRequired,
    activity: Types.activity.isRequired,
    handleDeleteLineItem: PropTypes.func
};

const defaultProps = {
    handleDeleteLineItem: null
};

function CartLineItem({ lineItem, activity, handleDeleteLineItem }) {
    const shouldDisplayFullDetails = !!handleDeleteLineItem;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const groupedLineItemTickets = groupBy(lineItem.lineItemTickets, "ticketId");
    const lineItemSubtotal = sumCurrencyValues(
        lineItem.lineItemTickets,
        "subtotal"
    );
    const lineItemTaxes = sumCurrencyValues(lineItem.lineItemTickets, "taxes");

    return (
        <div key={lineItem.id} className="order-line-item">
            <div className="product-item">
                {shouldDisplayFullDetails ? (
                    <h3 className="h5">{activity.displayName}</h3>
                ) : (
                    <h2 className="h5">{activity.displayName}</h2>
                )}
                <img src={activity.thumbnail} alt="" className="activity-thumbnail" />
            </div>
            <div className="ticket-info">
                {activity.timed && <p>{getTimeDisplayText(lineItem)}</p>}
                {Object.keys(groupedLineItemTickets).map((ticketGroupKey) => {
                    const tickets = groupedLineItemTickets[ticketGroupKey];
                    
                    return (
                        <p key={ticketGroupKey}>
                            {tickets.length} x {tickets[0].displayName}
                        </p>
                    );
                })}
            </div>

            {handleDeleteLineItem && (
                <React.Fragment>
                    <div className="shopping-edit">
                        <div>
                            <Link
                                to={`/activities/${lineItem.primaryActivityId}?line=${lineItem.id}`}
                                className="edit"
                            >
                                Edit Details
                            </Link>
                            <span aria-hidden="true">|</span>
                            <button
                                className="edit"
                                type="button"
                                aria-label={`Delete ${activity} tickets from cart`}
                                onClick={() => setShowDeleteConfirmation(true)}
                            >
                                Delete
                            </button>
                        </div>
                        <p>{displayCurrency(lineItemSubtotal)}</p>
                    </div>
                    {lineItemTaxes > 0 && (
                        <div className="shopping-edit">
                            <p>Taxes</p>
                            <p>{displayCurrency(lineItemTaxes)}</p>
                        </div>
                    )}
                </React.Fragment>
            )}
            <hr aria-hidden="true" />
            {showDeleteConfirmation && (
                <Modal onClose={() => setShowDeleteConfirmation(false)}>
                    <div className="remove-cart-item">
                        <h5>Delete Item</h5>
                        <p>{`Are you sure you want to delete ${lineItem.eventName} from your cart?`}</p>
                        <Button
                            type="button"
                            className="button-primary"
                            onClick={() => {
                                setIsDeleting(true);
                                return handleDeleteLineItem().catch(() => {
                                    setIsDeleting(false);
                                });
                            }}
                            aria-label={`Remove ${lineItem.eventName}`}
                            submitting={isDeleting}
                        >
                            Delete Item
                        </Button>
                        <button
                            className="back-link"
                            aria-label={`Cancel removing ${lineItem.eventName} from cart`}
                            onClick={() => setShowDeleteConfirmation(false)}
                            type="button"
                        >
                            Cancel
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    );
}

CartLineItem.propTypes = exact(propTypes);
CartLineItem.defaultProps = defaultProps;

export default React.memo(CartLineItem);
