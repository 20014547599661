import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as flashActions from "redux-flash";
import { Modal } from "lp-components";
import { isEmpty, find, last, filter } from "lodash";
import { selectors as activitySelectors } from "../reducer";
import { selectors as cartSelectors } from "../../orders/reducer";
import {
  scrollToTop,
  serializeUdfs,
  convertUdfsArrayToObj,
  trackViewItem,
  trackAddToCart,
} from "utils";
import * as apiActions from "api-actions";
import * as actions from "../actions";
import * as Types from "types";
import { ErrorState, LoadingState } from "components";
import {
  ActivityActions,
  ActivityHeader,
  ReviewBookingDetails,
  ReviewLineItem,
  SelectTickets,
} from "../components";
import {
  ActivityDateTimeForm,
  BookingUdfForm,
  AttendeeDetailsForm,
  CalendarBookingForm,
} from "../forms";
import moment from "moment";

const propTypes = {
  activity: Types.activity,
  activityTimes: PropTypes.arrayOf(Types.activityTime),
  cart: Types.cart,
  clearActivity: PropTypes.func.isRequired,
  clearActivityTimes: PropTypes.func.isRequired,
  clearTickets: PropTypes.func.isRequired,
  createLineItem: PropTypes.func.isRequired,
  createLineItemTicket: PropTypes.func.isRequired,
  deleteLineItem: PropTypes.func.isRequired,
  deleteLineItemTicket: PropTypes.func.isRequired,
  fetchActivity: PropTypes.func.isRequired,
  fetchActivityTimes: PropTypes.func.isRequired,
  fetchCart: PropTypes.func.isRequired,
  fetchTickets: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  isTimedActivity: PropTypes.bool,
  isCalendarSelect: PropTypes.bool,
  isDynamicCalendarSelect: PropTypes.bool,
  orderedActivityTimes: PropTypes.object,
  params: PropTypes.object.isRequired,
  tickets: PropTypes.arrayOf(Types.ticket),
  updateLineItem: PropTypes.func.isRequired,
  updateLineItemTicket: PropTypes.func.isRequired,
};

const defaultProps = {};

function Activity({
  activity,
  activityTimes,
  cart,
  clearActivityTimes,
  clearActivity,
  clearTickets,
  createLineItem,
  createLineItemTicket,
  deleteLineItem,
  deleteLineItemTicket,
  fetchActivity,
  fetchActivityTimes,
  fetchCart,
  fetchTickets,
  flashErrorMessage,
  isTimedActivity,
  isCalendarSelect,
  isDynamicCalendarSelect,
  orderedActivityTimes,
  params: { id },
  tickets,
  updateLineItem,
  updateLineItemTicket,
}) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedActivityTime, setSelectedActivityTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showBookingUdfForm, setShowBookingUdfForm] = useState(false);
  const [showAttendeeDetailsForm, setShowAttendeeDetailsForm] = useState(false);
  const [ticketToAdd, setTicketToAdd] = useState(null);
  const [lineItemId, setLineItemId] = useState(() => {
    const param = new URLSearchParams(window.location.search).get("line");
    return param ? parseInt(param) : null;
  });
  const activityLineItem =
    lineItemId && cart.lineItems
      ? find(cart.lineItems, { id: lineItemId })
      : null;
  const [bookingUdfs, setBookingUdfs] = useState(
    activityLineItem ? activityLineItem.bookingUdfs : []
  );
  const [bookingUdfsCollected, setBookingUdfsCollected] = useState(
    !!activityLineItem
  );
  const [savedLineItemData, setSavedLineItemData] = useState(null);

  useEffect(() => {
    fetchActivity(id).catch(() => {
      if (!error) setError("Could not load tickets for this event.");
    });
    fetchCart(cart ? cart.token : null).catch(() => {
      if (!error) setError("An error occurred while loading your cart.");
    });
    return () => {
      clearActivity();
      clearActivityTimes();
      clearTickets();
      setSelectedActivityTime(null);
    };
  }, [id]);

  // Ensure that we're not concatenating multiple API calls to fetch
  // Activity Times
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current && activity) {
      setIsLoading(true);

      const activityPromise = fetchActivityTimes({
        activityId: id,
        includeTickets: isDynamicCalendarSelect ? true : undefined,
        startDate: activity.defaultStartDate,
      });

      const linkedActivityPromises = activity.linkedActivities
        ? activity.linkedActivities.map((linkedActivity) =>
          fetchActivityTimes({
            activityId: linkedActivity.id,
            includeTickets: isDynamicCalendarSelect ? true : undefined,
            startDate: linkedActivity.defaultStartDate,
          })
        )
        : [];

      const allActivityPromises = [activityPromise, ...linkedActivityPromises];

      Promise.all(allActivityPromises)
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setError("Could not load tickets for this event.");
        });

      isFirstRun.current = false;
    }
  }, [activity]);

  useEffect(() => {
    if (activity && activity.timed && activityTimes) {
      const dateToSelect = selectedDate || moment(activity.defaultStartDate);
      setSelectedDate(dateToSelect);
    }
  }, [activityTimes]);

  useEffect(() => {
    if (activity && selectedActivityTime) {
      if (tickets) clearTickets();
      setSelectedDate(moment(selectedActivityTime.startDate))

      fetchTickets(
        selectedActivityTime.activityId,
        selectedActivityTime.centamanId || selectedActivityTime.id
      ).catch(() => {
        if (!error) setError("Could not load tickets for this event.");
      });
    }
  }, [selectedActivityTime]);

  useEffect(() => {
    if (activityLineItem && activityTimes) {
      const activityTime = activityTimes.find(
        (item) => item.centamanId === activityLineItem.eventId
      );
      setSelectedActivityTime(activityTime);
    }
  }, [activityLineItem, activityTimes]);

  useEffect(() => {
    trackViewItem(id);
  }, []);

  if (error) return <ErrorState message={error} />;
  if (!activity || isLoading || !cart) return <LoadingState />;

  const bookingUdfsRequired = !isEmpty(activity.bookingUdfs);
  const attendeeDetailsRequired = activity.collectAttendeeDetails;

  const getDefaultActivityTime = () => {
    if (!activityTimes) return null;
    if (isDynamicCalendarSelect) return null;
    if (!isCalendarSelect) return activityTimes[0];
    if (activityLineItem)
      return find(activityTimes, {
        centamanId: activityLineItem.eventId,
        activityId: parseInt(id),
      });
    return null;
  };
  const checkShouldSetDateTimeSelection = (defaultActivityTime) => {
    const defaultTimeIsValid =
      defaultActivityTime &&
      defaultActivityTime.activityId === parseInt(id) &&
      (!selectedActivityTime ||
        selectedActivityTime.activityId !== parseInt(id));
    const dateEditingInProgress =
      defaultActivityTime &&
      selectedDate &&
      selectedDate.format("YYYY-MM-DD") !== defaultActivityTime.startDate;

    return defaultTimeIsValid && !dateEditingInProgress;
  };
  const defaultActivityTime = getDefaultActivityTime();
  const shouldSetDateTimeSelection =
    checkShouldSetDateTimeSelection(defaultActivityTime);
  if (shouldSetDateTimeSelection) {
    setSelectedActivityTime(defaultActivityTime);
    setSelectedDate(moment(defaultActivityTime.startDate));
  }

  const showError = (error) => {
    scrollToTop();
    flashErrorMessage(
      error.message ||
      "Something went wrong. Please retry your registration details."
    );
  };

  const getInitialBookingUdfValues = () => {
    const previouslyAddedSession = find(cart.lineItems, {
      activityId: activity.id,
    });
    if (!previouslyAddedSession) return {};
    return convertUdfsArrayToObj(previouslyAddedSession.bookingUdfs);
  };

  const resetAfterTicketCreation = () => {
    if (!bookingUdfsCollected) setBookingUdfsCollected(true);
    if (showBookingUdfForm) setShowBookingUdfForm(false);
    if (showAttendeeDetailsForm) setShowAttendeeDetailsForm(false);
  };

  const addNewSession = (
    lineItemToReplace = null,
    newSelectedDate = null,
    newSelectedTime = null
  ) => {
    setLineItemId(null);
    setBookingUdfs(lineItemToReplace ? lineItemToReplace.bookingUdfs : []);
    setBookingUdfsCollected(!!lineItemToReplace);
    setSelectedActivityTime(newSelectedTime);
    setSelectedDate(newSelectedDate);
    setSavedLineItemData(lineItemToReplace);
  };

  const addTicket = (
    ticket,
    bookingUdfs = [],
    attendeeDetails = {},
    attendeeUdfs = []
  ) => {
    const ticketVals = {
      ...ticket,
      attendee: attendeeDetails,
      attendeeUdfs,
    };

    if (!activityLineItem) {
      return createLineItem(
        cart,
        selectedActivityTime,
        ticket,
        bookingUdfs,
        activity.id
      )
        .then(({ lineItems }) => {
          const lineItem = find(lineItems, {
            eventId: selectedActivityTime.centamanId,
          });
          setLineItemId(lineItem.id);
          createLineItemTicket(cart, lineItem, ticketVals)
            .then(() => {
              trackAddToCart(lineItem.activityId, ticketVals.price);
            })
            .catch(() => {
              showError({
                message:
                  "Could not add ticket to cart. Please check that all required fields are completed and try again.",
              });
            });
        })
        .then(() => {
          resetAfterTicketCreation();
        });
    } else {
      return createLineItemTicket(cart, activityLineItem, ticketVals).then(
        () => {
          trackAddToCart(activityLineItem.activityId, ticketVals.price);
          resetAfterTicketCreation();
        }
      );
    }
  };

  const replaceLineItem = (newActivityTime, oldLineItem = null) => {
    // if the time (but not the date) was changed, we are able to pass in the old line item directly from the ActivityDateTimeForm submission
    // however, if the date was changed, the old line item has to be saved in the interim as "savedLineItemData" while the user also selects a new time
    const replaceableLineItem = oldLineItem || savedLineItemData;
    if (!replaceLineItem) return;
    return deleteLineItem(cart, replaceableLineItem)
      .then(() => {
        return createLineItem(
          cart,
          newActivityTime,
          { activityTimeId: null },
          replaceableLineItem.bookingUdfs,
          activity.id
        );
      })
      .then(({ lineItems }) => {
        const newLineItem = find(lineItems, {
          eventId: newActivityTime.centamanId,
        });
        setLineItemId(newLineItem.id);
        replaceableLineItem.lineItemTickets.map((oldLineItemTicket) => {
          const ticket = find(tickets, { id: oldLineItemTicket.ticketId });
          const ticketVals = {
            ...ticket,
            // Typically spreading ticket should set id and price for the
            // replaced ticket; however with linked activities, we need to
            // ensure the replaced ticket includes the updated information
            // for these fields
            attendee: oldLineItemTicket.attendee,
            attendeeUdfs: oldLineItemTicket.attendeeUdfs,
            id: newActivityTime.tickets[0].id,
            price: newActivityTime.tickets[0].price,
          };
          createLineItemTicket(cart, newLineItem, ticketVals);
        });
      })
      .then(() => {
        setSavedLineItemData(null);
      })
      .catch((err) => {
        setSavedLineItemData(null);
        showError(err);
      });
  };

  return (
    <div className="content-choose-tickets">
      <div className="choose-tickets-top">
        <ActivityHeader activity={activity} />
      </div>
      <div className="choose-tickets-bottom">
        {isDynamicCalendarSelect && (
          <CalendarBookingForm
            activityLineItem={activityLineItem}
            activityTimes={activityTimes}
            cart={cart}
            orderedActivityTimes={orderedActivityTimes}
            setSelectedActivityTime={setSelectedActivityTime}
            onSubmit={(selectedActivity) => {
              if (!selectedActivity) return;
              else {
                const shouldReplaceLineItem =
                  savedLineItemData ||
                  (activityLineItem &&
                    activityLineItem.eventId !==
                    selectedActivityTime.centamanId);
                if (shouldReplaceLineItem) {
                  replaceLineItem(selectedActivityTime, activityLineItem);
                }
              }
            }}
          />
        )}

        {!isDynamicCalendarSelect && !isEmpty(activityTimes) && (
          <ActivityDateTimeForm
            activity={activity}
            activityTimes={activityTimes}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setSelectedActivityTime={setSelectedActivityTime}
            selectedActivityTime={selectedActivityTime}
            fetchActivityTimes={fetchActivityTimes}
            onSubmit={({ activityTimeId, date }) => {
              if (activityLineItem) {
                addNewSession(activityLineItem, date || selectedDate);
              }
              if (!activityTimeId) return;
              const activityTime = find(activityTimes, {
                centamanId: parseInt(activityTimeId),
              });
              setSelectedActivityTime(activityTime);
              const shouldReplaceLineItem =
                savedLineItemData ||
                (activityLineItem &&
                  activityLineItem.eventId !== activityTimeId);
              if (shouldReplaceLineItem) {
                replaceLineItem(activityTime, activityLineItem);
              }
            }}
            initialValues={{
              ...(selectedActivityTime && {
                activityTimeId: selectedActivityTime.centamanId,
              }),
            }}
          />
        )}
        {isTimedActivity && isEmpty(activityTimes) && (
          <p className="no-available-times h5">
            We're sorry, but there are no available times for this activity.
            Please return to the activities page and select a new activity.
          </p>
        )}
        <div className="choose-tickets-bottom">
          {activityLineItem && !isEmpty(activityLineItem.bookingUdfs) && (
            <ReviewBookingDetails
              cart={cart}
              lineItem={activityLineItem}
              updateLineItem={updateLineItem}
              activity={activity}
              showError={showError}
            />
          )}
          {selectedActivityTime && (
            <SelectTickets
              tickets={tickets}
              activityTime={selectedActivityTime}
              isTimed={activity.timed}
              lineItem={activityLineItem}
              maxQuantity={activity.maxQuantity}
              showError={showError}
              addTicket={(ticket) => {
                if (!bookingUdfsRequired && !attendeeDetailsRequired) {
                  addTicket(ticket);
                  return;
                }

                if (bookingUdfsRequired && !bookingUdfsCollected) {
                  setTicketToAdd(ticket);
                  setShowBookingUdfForm(true);
                  return;
                }

                if (attendeeDetailsRequired) {
                  setTicketToAdd(ticket);
                  setShowAttendeeDetailsForm(true);
                  return;
                }

                addTicket(ticket);
              }}
              removeTicket={(ticket) => {
                const correspondingTickets = filter(
                  activityLineItem.lineItemTickets,
                  { ticketId: ticket.id }
                );
                const lineItemTicket = last(correspondingTickets);
                const lineItemWillBeDeleted =
                  activityLineItem.lineItemTickets.length - 1 <= 0;
                return deleteLineItemTicket(
                  cart,
                  activityLineItem,
                  lineItemTicket
                )
                  .then(() => {
                    if (lineItemWillBeDeleted) setLineItemId(null);
                  })
                  .catch((err) => showError(err));
              }}
            />
          )}
          {activityLineItem && (
            <ReviewLineItem
              lineItem={activityLineItem}
              cart={cart}
              activity={activity}
              updateLineItemTicket={updateLineItemTicket}
              deleteLineItemTicket={(ticket) => {
                deleteLineItemTicket(cart, activityLineItem, ticket).catch(
                  (err) => showError(err)
                );
              }}
              showError={showError}
            />
          )}
          {showBookingUdfForm && (
            <Modal
              onClose={() => setShowBookingUdfForm(false)}
              hideCloseButton={true}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
            >
              <BookingUdfForm
                activity={activity}
                onSubmit={(params) => {
                  const serializedBookingUdfs = serializeUdfs(
                    params,
                    activity.bookingUdfs
                  );

                  if (attendeeDetailsRequired) {
                    setBookingUdfs(serializedBookingUdfs);
                    setShowAttendeeDetailsForm(true);
                  } else {
                    addTicket(ticketToAdd, serializedBookingUdfs).catch((err) =>
                      showError(err)
                    );
                  }
                }}
                onCancel={() => setShowBookingUdfForm(false)}
                initialValues={getInitialBookingUdfValues()}
              />
            </Modal>
          )}
          {activity.collectAttendeeDetails && showAttendeeDetailsForm && (
            <Modal
              onClose={() => setShowAttendeeDetailsForm(false)}
              hideCloseButton={true}
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEsc={false}
            >
              <AttendeeDetailsForm
                activity={activity}
                onSubmit={({ attendeeDetails, attendeeUdfs }) => {
                  const formattedAttendeeDetails = {
                    ...attendeeDetails,
                    dateOfBirth: parseDateOfBirth(attendeeDetails.dateOfBirth),
                  };
                  const serializedUdfs = attendeeUdfs
                    ? serializeUdfs(attendeeUdfs, activity.attendeeUdfs)
                    : [];
                  addTicket(
                    ticketToAdd,
                    bookingUdfs,
                    formattedAttendeeDetails,
                    serializedUdfs
                  ).catch((err) => showError(err));
                }}
                onCancel={() => setShowAttendeeDetailsForm(false)}
              />
            </Modal>
          )}
          <ActivityActions
            handleAddNewSession={
              activity.timed && activityLineItem ? addNewSession : null
            }
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    activity: activitySelectors.activity(state),
    activityTimes: activitySelectors.activityTimes(state),
    isTimedActivity: activitySelectors.isTimedActivity(state),
    isCalendarSelect: activitySelectors.isCalendarSelect(state),
    isDynamicCalendarSelect: activitySelectors.isDynamicCalendarSelect(state),
    tickets: activitySelectors.tickets(state),
    orderedActivityTimes: activitySelectors.orderedActivityTimes(state),
    cart: cartSelectors.cart(state),
  };
}

const mapDispatchToProps = {
  fetchActivity: apiActions.fetchActivity,
  fetchActivityTimes: apiActions.fetchActivityTimes,
  clearActivity: actions.clearActivity,
  clearActivityTimes: actions.clearActivityTimes,
  fetchTickets: apiActions.fetchTickets,
  clearTickets: actions.clearTickets,
  fetchCart: apiActions.fetchCart,
  createLineItem: apiActions.createLineItem,
  updateLineItem: apiActions.updateLineItem,
  deleteLineItem: apiActions.deleteLineItem,
  createLineItemTicket: apiActions.createLineItemTicket,
  updateLineItemTicket: apiActions.updateLineItemTicket,
  deleteLineItemTicket: apiActions.deleteLineItemTicket,
  flashErrorMessage: flashActions.flashErrorMessage,
};

const parseDateOfBirth = (dob) => {
  if (!dob) return null;
  return dob.format("YYYY-MM-DD");
};

Activity.defaultProps = defaultProps;
Activity.propTypes = propTypes;

export default compose(connect(mapStateToProps, mapDispatchToProps))(Activity);
