import moment from "moment";

Date.prototype.addHours = function (h) {
  this.setHours(this.getHours() + h);
  return this;
};

Date.prototype.subtractHours = function (h) {
  this.setHours(this.getHours() - h);
  return this;
};

Date.prototype.addMinutes = function (h) {
  this.setMinutes(this.getMinutes() + h);
  return this;
};

export function parseDate(date) {
  var array = date.split("-");
  var year = array[0];
  var month = array[1];
  var day = array[2].split("T")[0];
  var newDate = new Date(year, month - 1, day);
  return newDate;
}

export function parseTime(time) {
  if (!time) {
    return { hour: null, minute: null };
  } else {
    var timeElements = time.split(":");
    var hour = timeElements[0];
    var minute = timeElements[1];
    return { hour: parseInt(hour, 10), minute: parseInt(minute, 10) };
  }
}

export function formatTime(startDate, startTime) {
  const timeObject = parseTime(startTime);
  const date = parseDate(startDate);
  const startDateWithTime = new Date(date);
  startDateWithTime.addHours(timeObject.hour);
  startDateWithTime.addMinutes(timeObject.minute);
  return startDateWithTime;
}

export function getTimeDisplayText(lineItem) {
  return (
    moment(lineItem.startDate).format("MMMM DD, YYYY") +
    " at " +
    lineItem.displayStartTime
  );
}

export function removeInvalidDates(data = {}, pathName) {
  if (data.primaryMember) {
    if (moment(data.primaryMember[pathName]).year() < 1)
      delete data.primaryMember[pathName];
  }
  if (data.secondaryMembers) {
    data.secondaryMembers.forEach((member) => {
      if (moment(member[pathName]).year() < 1) delete member[pathName];
    });
  }
  return data;
}

export function reformatDatesForApi(data = {}) {
  if (data.primaryMember) {
    data.primaryMember.dateOfBirth = formatYearMonthDay(
      data.primaryMember.dateOfBirth
    );
  }
  if (data.secondaryMembers) {
    data.secondaryMembers.forEach((member) => {
      member.dateOfBirth = formatYearMonthDay(member.dateOfBirth);
    });
  }
  return data;
}

export function formatYearMonthDay(date = null) {
  const base = date ? moment(date) : moment();
  return base.format("YYYY-MM-DD");
}

export function formatFullDate(date) {
  return moment(date).format("MMMM D, YYYY");
}

export function formatFullDateTime(date) {
  return moment(date).format("MMMM D, YYYY [at] h:mm a");
}

export function formatDateDashed(date) {
  return moment(date).format("M-D-YY");
}

export function formatDateTimeDashed(date) {
  return moment(date).format("M-D-YY [at] h:mm a");
}

// Format as string YYYY-MM-DD
export function toDateString(date) {
  return date.toISOString().split("T")[0];
}

export function formatDateFullDayAndDate(date) {
  return new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(date);
}