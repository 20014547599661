import moment from 'moment'

/* CONSTANTS */
const AUTH_TOKEN_KEY = 'streamlined-checkout-token'
const MEMBER_DETAILS_KEY = 'member-details'
const MEMBERSHIP_ID_KEY = 'membership-id'
const CHECKOUT_KEY = 'checkout'
const ORDER_INFO_KEY = 'orderInfo'
const CART_KEY = 'cart'

export function setOrderInfo (orderInfo) {
  return sessionStorage.setItem(ORDER_INFO_KEY, JSON.stringify(orderInfo))
}

export function getOrderInfo () {
  const orderInfo = sessionStorage.getItem(ORDER_INFO_KEY)
  if (!orderInfo) return null 
  const orderInfoObject = JSON.parse(orderInfo)
  orderInfoObject.startDate = moment(orderInfoObject.startDate)
  orderInfoObject.endDate = moment(orderInfoObject.endDate)
  return orderInfoObject
}

export function setCart (cartToken) {
  return sessionStorage.setItem(CART_KEY, JSON.stringify(cartToken))
}

export function getCart () {
  const cart = sessionStorage.getItem(CART_KEY)
  return JSON.parse(cart)
}

/* AUTH */

export function getAuthToken () {
  return getItem(AUTH_TOKEN_KEY)
}

export function setAuthToken (token, persist = true) {
  return setItem(AUTH_TOKEN_KEY, token, persist)
}

export function clearAuthToken () {
  return removeItem(AUTH_TOKEN_KEY)
}

/* MEMBERSHIP */

export function getCheckout () {
  return getItem(CHECKOUT_KEY) || {}
}

export function setMembershipId (id) {
  return setItem(MEMBERSHIP_ID_KEY, id)
}

export function getMembershipId () {
  return getItem(MEMBERSHIP_ID_KEY)
}

export function getMembershipDetails () {
  const encodedMembership = getItem(MEMBER_DETAILS_KEY)
  if (!encodedMembership) return {}
  
  return JSON.parse(atob(encodedMembership))
}

export function setMembershipDetails (member) {
  setItem(MEMBER_DETAILS_KEY, btoa(JSON.stringify(member)))
}

export function clearMembershipDetails () {
  removeItem(MEMBER_DETAILS_KEY)
}

export function getMembershipToken () {
  return getMembershipDetails().token
}

export function isLoggedIn () {
  return !!getMembershipToken()
}

export function clearMembershipOrderDetails () {
  removeItem(CHECKOUT_KEY)
  removeItem(MEMBER_DETAILS_KEY)
  removeItem(MEMBERSHIP_ID_KEY)
}

/* HELPERS */

// Get item from localStorage, falling back to session storage
function getItem (key) {
  const item = localStorage.getItem(key) || sessionStorage.getItem(key)
  return item ? JSON.parse(item) : null
}

// Remove item from local storage and session storage
function removeItem (key) {
  localStorage.removeItem(key)
  sessionStorage.removeItem(key)
}

export function lookupItem (key) {
  return getItem(key)
}

// Set item in local storage or session storage (if specified)
export function setItem (key, value, options = {}) {
  const { persist = true } = options
  const stringifiedValue = JSON.stringify(value)
  removeItem(key)
  return persist
    ? localStorage.setItem(key, stringifiedValue)
    : sessionStorage.setItem(key, stringifiedValue)
}

/**
 * Given a key, returns functions for getting, setting, and clearing that key
 * @example
 * 
 * export const [
 *  getSelectedMembership,
 *  setSelectedMembership,
 *  clearSelectedMembership
 * ] = createStorageAccessor('selectedMembership', { persist: false })
 * 
**/
export function createStorageAccessor (key, defaultOptions = {}) {
  const get = () => getItem(key)
  const set = (value, options = {}) => setItem(key, value, { ...defaultOptions, ...options })
  const clear = () => removeItem(key)
  
  return [get, set, clear]
}

