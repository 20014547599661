import { handleActions, combineActions } from 'redux-actions'
import { selectorForSlice } from 'lp-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import * as LS from 'local-storage'
import moment from 'moment'
import * as actions from './actions'

const reducerKey = 'orders'
const slice = 'root.orders'

const currentDate = moment()

const defaultOrderInfo = {
  activityTimeId: null,
  activityId: null,
  bookingTypeId: null,
  startDate: currentDate,
  endDate: currentDate,
  totalTaxes: 0,
  orderTotal: 0,
  orderSubtotal: 0,
  orderTickets: {},
  orderAddOns: {},
  bookingUdfForms: {},
  coupons: []
}

const initialState = {
  cart: LS.getCart(),
  orderInfo: LS.getOrderInfo() || defaultOrderInfo
}

const reducer = handleActions({
  [combineActions(
    apiActions.fetchCart, 
    apiActions.updateCart,
    apiActions.createLineItem, 
    apiActions.updateLineItem,
    apiActions.deleteLineItem,
    apiActions.createLineItemTicket,
    apiActions.updateLineItemTicket,
    apiActions.deleteLineItemTicket,
    apiActions.validateCoupon,
    apiActions.deleteCoupon)]: setOnSuccess('cart'),
    [apiActions.fetchTicketingOrder]: setOnSuccess('ticketingOrder'),
    [actions.setOrderInfo]: (state, { payload: orderInfo }) => {
      const oldOrderInfo = state.orderInfo
      const newOrderInfo = { ...oldOrderInfo, ...orderInfo }
      return setOnSuccess('orderInfo', newOrderInfo, state)
    },
    [actions.clearOrderInfo]: () => {
      setOnSuccess('orderInfo', defaultOrderInfo)
    }
}, initialState)

const select = selectorForSlice(slice)

const selectors = {
  cart: select('cart'),
  order: select('order.success.data.attributes', {}),
  ticketingOrder: select('ticketingOrder'),
  orderInfo: select('orderInfo'),
}

export { reducer, selectors, reducerKey }
